<template>
  <v-container>
      <h1>Projets</h1>
      <div class="d-flex flex-row">
        <p>Une partie de mes projets sont en visibilité privée sur GitHub/GitLab pour cause de confidentialité. Je peux néanmoins, vous en parlez si vous le souhaitez.</p>
        <!-- <v-btn >Me contacter</v-btn> -->
      </div>
      <v-expansion-panels class="mt-6">
        <v-expansion-panel
          style="background-color: #393E46;color: #EEE;"
          v-for="project in projects"
          :key="project.name"
        >
          <v-expansion-panel-header>
            <div class="d-flex justify-space-between">
              <v-p>
                {{ project.name }}
              </v-p>
              <v-p class="pr-5">
                {{ project.langage }}
              </v-p>
            </div>
            <template v-slot:actions>
              <v-icon color="#FD7014">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex flex-row align-center">
              <div>
                {{ project.description }}
              </div>
              
              <v-btn v-if="project.btnText"
                class="ml-3"
                color="#FD7014"
                :href="project.url"
                target="_blank"
                rel="noopener noreferrer"
              > 
                {{ project.btnText }} 
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>


  </v-container>
</template>

<script>
  export default {
    name: 'ProjectPage',

    data: () => ({
      projects: [
        {
          name: "Le projet du Parc Naturel Régional du Golfe du Morbihan ",
          description: "Créer une application permettant de saisir et de visualiser les données de différentes espèces. Java est le langage qui a été utilisé avec JavaFX comme « framework »(cadriciel) graphique.",
          image: "../assets/Youenn.jpg",
          langage: "Java & JavaFX",
          btnText: "Dépôt Github",
          url: "https://github.com/FeebyxDev/PNR-SAE"
        },
        {
          name: "Le Projet de développement Web « Lookids »",
          description: "Lookids est une entreprise créée par un étudiant de l’Université Bretagne Sud. Notre but était de concevoir un site web pour promouvoir son travail. Notre site a été sélectionné pour être implémenté.",
          image: "../assets/Youenn.jpg",
          langage: "HTML, CSS, JS & PHP",
          btnText: "Site Lookids",
          url: "https://lookids.fr/"
        },
        {
          name: "Les projets de robots Discord",
          description: "Création de plusieurs robots Discord permettant d’écouter de la musique dans des salons vocaux, des utilitaires de gestions de serveurs Discord. Les robots sont réalisés avec Node.JS accompagné de la librairie discord.js.",
          image: "../assets/Youenn.jpg",
          langage: "Node.JS & discord.js",
          btnText: "",
          url: ""
        },
        {
          name: "Projet automatisation d'un portail avec lecture de plaque d'immatriculation",
          description: "Création d'un portail automatique avec reconnaissance de plaque d'immatriculation. Le portail est réalisé avec un Arduino et un Raspberry Pi. Le Raspberry Pi est relié à une caméra et à un module de reconnaissance de plaque d'immatriculation.",
          image: "../assets/Youenn.jpg",
          langage: "Node.JS, OpenALPR & Arduino",
          btnText: "Dépôt GitLab",
          url: "https://gitlab.com/Feebyx/projet-sin"
        },
        {
          name: "Le projet d’aides numérique",
          description: "Le projet consistait à récolter des ordinateurs à des écoles, entreprise et aux particuliers pour les donner à une association « l’Odyssée de Quimper » à destination des mineurs étrangers isolés. ",
          image: "../assets/Youenn.jpg",
          btnText: "Lien vers l’association",
          url: "https://www.fmt.bzh/etablissements/odyssee/"
        }
      ]
    }),
  }
</script>
