<template>
  <v-footer
    fixed
    dark
    padless
  >
    <v-card
      class="flex"
      flat
      tile
    >
      <v-card-title style="background-color: #393E46;color:#EEE;">
        <strong class="subheading">N'hésite pas à aller voir mes réseaux sociaux !</strong>

        <v-spacer></v-spacer>

        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4"
          dark
          icon
          :href="icon.href"
          target="_blank"
        >
          <v-icon size="24px" color="#FD7014">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text style="color: #FD7014;" class="py-2 text-center">
        {{ new Date().getFullYear() }} — <strong>Youenn Jamard</strong>
      </v-card-text>
    </v-card>
  </v-footer>

</template>

<script>
  // console.log(HomeVue);

  export default {
    name: 'FooterBar',
    components: {
      /* HomeVue,
      Projects, */
    },
    computed : {
    },
    data() {
      return {
        icons: [
          {
            icon: 'mdi-linkedin',
            href: 'https://www.linkedin.com/in/youenn-jamard/',
          },
          {
            icon: 'mdi-github',
            href: 'https://github.com/FeebyxDev'
          },
          {
            icon: 'mdi-gitlab',
            href: 'https://gitlab.com/Feebyx',
          },
          {
            icon: 'mdi-twitter',
            href: 'https://twitter.com/JamardYouenn',
          },
          {
            icon: 'mdi-instagram',
            href: 'https://www.instagram.com/feebyxx/',
          },
        ],
      }
    },
  }
</script>
