<template>
  <div>

    <v-card>
      <v-card-title class="text-center justify-center py-6" style="background-color: #393E46;">
        <h1 class="font-weight-bold text-h2" style="color: #EEE;">
          Youenn Jamard
        </h1>
      </v-card-title>
  
      <v-tabs
        v-model="tab"
        grow
      >
        <v-tabs-slider color="#FD7014"></v-tabs-slider>
  
        <v-tab
          style="background-color: #393E46;"
          v-for="item in menu"
          :key="item"
        >
        <v-icon color="#FD7014">{{item.icon}}</v-icon>
        <span class="ml-2" style="color: #EEE;" >{{ item.text }}</span>
        </v-tab>
  
      </v-tabs>
    </v-card>

    <v-tabs-items v-model="tab" class="grey darken-4" style="color: #EEE;">
      <v-tab-item
        v-for="item in menu"
        :key="item"
      >
        <component 
          v-bind:is="currentComponent" 
          class="d-flex flex-column"
          style="width: 50%;"
        />
      </v-tab-item>
    </v-tabs-items>

  </div>

</template>

<script>
  import HomeVue from './Home.vue';
  import Projects from './Projects.vue';
  import Contact from './Contact.vue';
  import Timeline from './Timeline.vue';

  export default {
    name: 'HeaderBar',
    computed : {
      currentComponent () {
        return this.menu[this.tab].component;
      },
    },
    data() {
      return {
        menu: [
        {
          text: 'Accueil',
          icon: 'mdi-home',
          component: HomeVue,
        },
        {
          text: 'Mon Parcours',
          icon: 'mdi-school',
          component: Timeline,
        },
        {
          text: 'Mes projets',
          icon: 'mdi-folder',
          component: Projects,
        },
        {
          text: 'Contact',
          icon: 'mdi-email',
          component: Contact,
        },
      ],
      tab: 0,
      }
    },
  }
</script>
