<template>
  <v-app style="background-color: #212121;">
    
    <HeaderBar />

    <Footer />


  </v-app>
</template>

<script>
  /* import HomeVue from './components/Home.vue';
  import Projects from './components/Projects.vue';
  import Contact from './components/Contact.vue';
  import About from './components/About.vue'; */
  // import HelloWorld from './components/HelloWorld';
  import HeaderBar from './components/HeaderBar.vue';
  import Footer from './components/Footer.vue';
  
  export default {
    name: 'App',
    components: {
    // HelloWorld,
    HeaderBar,
    Footer,
},
  };
</script>
