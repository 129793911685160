<template>
  <v-container>
    <h1>Contact</h1>

    <v-row>
      <v-col
        cols="6"
        align-self="start"
      >


        <v-card
          style="margin-top: 20px;background-color: #393E46;"
          max-width="400"
          outlined
        >
          <v-card-title
            style="padding-bottom: 2px;"
          >
            <v-icon
              size="25"
              class="mr-4"
              color="deep-orange darken-1"
            >
              mdi-email
            </v-icon>
            <h2 class="text-h5 font-weight-light" style="color:#eee;">
              Email
            </h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="12"
                style="padding-top: 2px;"
              >
                <a href="mailto:contact@fbyx.fr" style="color:#FFAB91;">contact@fbyx.fr</a><br>
                <a href="mailto:youenn.jamard@live.fr" style="color:#FFAB91;">youenn.jamard@live.fr</a>
              </v-col>
            </v-row>
          </v-container>
        </v-card>


        <v-card
          style="margin-top: 20px;background-color: #393E46;"
          max-width="400"
          outlined
        >
          <v-card-title
            style="padding-bottom: 2px;"
          >
            <v-icon
              size="25"
              class="mr-4"
              color="deep-orange darken-1"
            >
              mdi-linkedin
            </v-icon>
            <h2 class="text-h5 font-weight-light" style="color:#eee;">
              Linkedin
            </h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="12"
                style="padding-top: 2px;"
              >
                <a href="https://www.linkedin.com/in/youenn-jamard/" style="color:#FFAB91;">linkedin.com/in/youenn-jamard/</a>
              </v-col>
            </v-row>
          </v-container>
        </v-card>





      </v-col>
      <v-col
        cols="6"
        align-self="start"
        
      >
        <iframe src="https://www.google.com/maps/embed/v1/view?key=AIzaSyCpL94_DjBJb0NoX0WoIJ6dTDDX32lbdSA&center=47.6331476,-2.873276&zoom=11" width="100%" height="300" frameborder="0" style="border: 0" allowfullscreen></iframe>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'ContactPage',

    data: () => ({
      
    }),
  }
</script>
